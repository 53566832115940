<template>
  <div>
    <el-card>
      <el-table
        border
        stripe
        :data="couponDetailTotal"
      >
        <el-table-column
          label="用户id"
          prop="userId"
        ></el-table-column>
        <el-table-column
          label="用户名"
          prop="userName"
        ></el-table-column>
        <el-table-column
          label="店铺名称"
          prop="merchantsName"
        ></el-table-column>
        <el-table-column
          label="优惠券名称"
          prop="couponName"
        ></el-table-column>
        <el-table-column
          label="优惠金额"
          prop="couponValue"
        ></el-table-column>
        <el-table-column
          label="订单金额"
          prop="orderAmount"
        ></el-table-column>
        <el-table-column
          label="支付金额"
          prop="payAmount"
        ></el-table-column>
        <el-table-column
          label="订单号"
          prop="orderNo"
          width="180"
        ></el-table-column>
        <el-table-column
          label="支付时间"
          prop="payTime"
          width="200"
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      queryInfo: {
        limit: 15,
        pageNo: 1
        // merchantId: ''
      },
      couponDetailTotal: [],
      total: 0

    }
  },
  created () {
    this.getCouponDetailList()
  },
  methods: {
    async getCouponDetailList () {
      const { data: res } = await this.$http.get(`/agriculturalCoupon/useDetail/${this.$route.query.id}`, {
        params: this.queryInfo
      })
      if (res.code === 0) {
        this.couponDetailTotal = res.data.data.map(item => ({
          ...item,
          couponValue: item.couponValue / 100,
          orderAmount: item.orderAmount / 100,
          payAmount: item.payAmount / 100
        }))
        this.total = res.data.total
      } else {
        this.$message.error(res.message)
      }
      // console.log(res)
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getCouponDetailList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getCouponDetailList()
    }
  }
}
</script>

<style lang="less" scoped>
.input {
  display: flex;
  width: 340px;
  .el-select {
    position: relative;
    top: -10px;
  }
}
</style>
